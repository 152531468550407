import {
  email,
  helpers,
  required,
  sameAs,
} from '@vuelidate/validators';
import {
  toRef,
} from 'vue';

export default (formData) => ({
  name: {
    required: helpers.withMessage('Full name is required', required),
  },
  email: {
    required: helpers.withMessage('Email address is required', required),
    email: helpers.withMessage('Email address must be a valid email', email),
  },
  password: { required: helpers.withMessage('Password is required', required) },
  password_confirmation: { sameAs: helpers.withMessage('Passwords do not match', sameAs(toRef(formData, 'password'))) },
});

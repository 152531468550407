import {
  email, helpers, required,
} from '@vuelidate/validators';

export default () => ({
  email: {
    required: helpers.withMessage('Email address is required', required),
    email: helpers.withMessage('Email address must be a valid email', email),
  },
});

<script setup>
import AuthCard from '@/components/AuthCard.vue';
import {
  reactive, ref, computed,
} from 'vue';
import FieldTwoFactor
  from '@/components/fields/FieldTwoFactor.vue';

const isLoading = ref(false);

const formData = reactive({
  code: '',
  password: '',
});

const disableSubmit = computed(() => formData.code.length !== 6);

const submit = () => {
//
};

</script>

<template>
  <AuthCard>
    <div class="mb-5">
      <h2 class="h3">
        Security Code
      </h2>
      <p>Enter the two factor code generated by your authenticator app.</p>
    </div>

    <form @submit.prevent="submit">
      <div class="mb-3">
        <label
          class="form-label"
          for="twoFactorCode"
        >2FA Code</label>
        <field-two-factor
          id="twoFactorCode"
          @update:code="formData.code = $event"
        />
      </div>
      <div class="pt-5">
        <button
          type="submit"
          class="btn btn-primary btn-lg d-block w-100 mb-3"
          :class="[{'disabled': isLoading}]"
          :disabled="disableSubmit"
        >
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </span>
          <span v-else><span class="material-icons-outlined me-2">vpn_key</span> Authenticate</span>
        </button>
        <div class="text-center">
          <router-link
            :to="{name:'login'}"
            class="text-primary fw-bold d-inline-flex align-items-center text-decoration-none"
          >
            <span class="material-icons-outlined me-2">arrow_back</span> Back to Sign In
          </router-link>
        </div>
      </div>
    </form>
  </AuthCard>
</template>

<style scoped>

</style>

<script setup>
import {
  reactive, ref,
} from 'vue';

import registerRules from '@/validation/rules/register.rules';

import AuthCard from '@/components/AuthCard.vue';
import LoaderButton from '@/components/LoaderButton.vue';
import ValidationMessage from '@/components/ValidationMessage.vue';

import useVuelidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import UseShake from '@/composables/useShake';
import useAuthStore
  from '@/store/auth.store';
import {
  ROUTE,
} from '@/const';
import {
  useRouter,
} from 'vue-router';

const toast$ = useToast();
const router = useRouter();

const authStore = useAuthStore();
const { register } = authStore;

const isLoading = ref(false);

const formData = reactive({
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
});

const $externalResults = ref({});
const v$ = useVuelidate(registerRules(formData), formData, { $autoDirty: true, $externalResults });

const { shake, isShaking } = UseShake();

const submit = () => {
  v$.value.$validate().then((isValid) => {
    if (isValid) {
      isLoading.value = true;
      register(formData).then(() => {
        router.push({ name: ROUTE.CHECK_EMAIL });
      }).catch((error) => {
        const errors = error?.response?.data?.errors;
        if (errors) {
          $externalResults.value = errors;
        }
        // TODO: Handle backend validation messages
        shake();
      }).finally(() => {
        isLoading.value = false;
      });
    } else {
      shake();
      toast$.error('There are problems with the form. Please correct them and try again.');
    }
  });
};

</script>

<template>
  <AuthCard :shake="isShaking">
    <div class="mb-5">
      <h2 class="h3">
        Create your account
      </h2>
      <p>Start taking control of your communications. Sign up and start adding your Mailgun accounts.</p>
    </div>

    <form @submit.prevent="submit">
      <div class="form-floating mb-3">
        <input
          id="name"
          v-model="formData.name"
          type="text"
          class="form-control"
          placeholder="Full Name"
          autocomplete="name"
        >
        <label for="name">Full Name</label>
        <ValidationMessage :errors="v$.name.$errors" />
      </div>
      <div class="form-floating mb-3">
        <input
          id="email"
          v-model="formData.email"
          type="email"
          class="form-control"
          placeholder="name@example.com"
          autocomplete="email"
        >
        <label for="email">Email address</label>
        <ValidationMessage :errors="v$.email.$errors" />
      </div>
      <div class="form-floating mb-3">
        <input
          id="password"
          v-model="formData.password"
          type="password"
          class="form-control"
          placeholder="Password"
          autocomplete="new-password"
        >
        <label for="password">Password</label>
        <ValidationMessage :errors="v$.password.$errors" />
      </div>
      <div class="form-floating mb-3">
        <input
          id="repeatPassword"
          v-model="formData.password_confirmation"
          type="password"
          class="form-control"
          placeholder="Password"
          autocomplete="new-password"
        >
        <label for="repeatPassword">Repeat Password</label>
        <ValidationMessage :errors="v$.password_confirmation.$errors" />
      </div>
      <div class="pt-5">
        <LoaderButton
          class="btn btn-primary btn-lg d-block w-100 mb-3"
          :is-loading="isLoading"
          :disabled="isLoading"
        >
          <span class="material-icons-outlined me-2">done</span> Create your account
        </LoaderButton>
        <div class="text-center">
          <router-link
            :to="{name:'login'}"
            class="text-primary fw-bold d-inline-flex align-items-center text-decoration-none"
          >
            <span class="material-icons-outlined me-2">arrow_back</span> Back to Sign In
          </router-link>
        </div>
      </div>
    </form>
  </AuthCard>
</template>

<style scoped>

</style>

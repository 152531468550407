<script setup>
import AuthCard from '@/components/AuthCard.vue';
import {
  reactive, ref, computed,
} from 'vue';
import useAuthStore
  from '@/store/auth.store';
import {
  storeToRefs,
} from 'pinia';
import {
  useToast,
} from 'vue-toastification';

const authStore = useAuthStore();
const { logout, resendEmailVerification, canSendVerifyEmail } = authStore;

const toast$ = useToast();

const isLoading = ref(false);
const canResendEmail = ref(true);

const resendEmail = () => {
  canResendEmail.value = false;
  resendEmailVerification().then(() => {
    toast$.success('Verification email has been resent');
  });
};

const checkConditions = () => {
  canResendEmail.value = canSendVerifyEmail();
};

setInterval(checkConditions, 1000);

checkConditions();

const submit = () => {
//
};

</script>

<template>
  <AuthCard>
    <div class="mb-5">
      <h2 class="h3 mb-3">
        Confirm Your Email Address
      </h2>
      <p>
        Please confirm your email address by following the link in the email we've sent you.
      </p>
      <p>
        Still not received an email? <a
          href="javascript:void(0)"
          class="fw-bold"
          :class="[{'opacity-50 pointer-events-none':!canResendEmail}]"
          @click="resendEmail"
        >Send the email again{{ !canResendEmail ? ' shortly' : '' }}.</a>
      </p>
    </div>
    <div>
      <router-link
        :to="{name:'login'}"
        class="btn btn-primary btn-lg d-block w-100 mb-4"
      >
        Continue
      </router-link>
      <div class="text-center">
        <button
          class="text-primary btn btn-link fw-bold d-inline-flex align-items-center text-decoration-none"
          @click="logout"
        >
          <span class="material-icons-outlined me-2">arrow_back</span> Cancel & Return to Sign In
        </button>
      </div>
    </div>
  </AuthCard>
</template>

<style scoped>

</style>

<script setup>
import AuthCard from '@/components/AuthCard.vue';
import {
  reactive, ref, computed,
} from 'vue';
import LoaderButton from '@/components/LoaderButton.vue';
import ValidationMessage from '@/components/ValidationMessage.vue';
import useVuelidate
  from '@vuelidate/core';
import requestPasswordResetRules
  from '@/validation/rules/requestPasswordReset.rules';
import UseShake
  from '@/composables/useShake';
import useAuthStore
  from '@/store/auth.store';
import {
  storeToRefs,
} from 'pinia';
import {
  formDataPreflight,
} from '@/helpers/form.helpers';
import {
  ROUTE,
} from '@/const';
import {
  useToast,
} from 'vue-toastification';
import {
  useRouter,
} from 'vue-router';

const toast$ = useToast();
const router = useRouter();

const authStore = useAuthStore();
const { email } = storeToRefs(authStore);
const { requestPasswordReset } = authStore;

const isLoading = ref(false);

const formData = reactive({
  email,
});

const v$ = useVuelidate(requestPasswordResetRules(), formData, { $autoDirty: true });

const { shake, isShaking } = UseShake();

const submit = () => {
  v$.value.$validate().then((isValid) => {
    if (isValid) {
      isLoading.value = true;
      const form = formDataPreflight(formData);
      requestPasswordReset(form).then(() => {
        toast$.success('Password reset link has been sent to your email address');
        router.push({ name: ROUTE.LOGIN });
      }).catch(() => {
        shake();
      }).finally(() => {
        isLoading.value = false;
      });
    } else {
      shake();
      toast$.error('There are problems with the form. Please correct them and try again.');
    }
  });
};

</script>

<template>
  <AuthCard :shake="isShaking">
    <div class="mb-5">
      <h2 class="h3">
        Reset Your Password
      </h2>
      <p>Enter the email address associated with your account. You'll be sent an email with a link to reset your password. </p>
    </div>

    <form @submit.prevent="submit">
      <div class="form-floating mb-3">
        <input
          id="email"
          v-model="formData.email"
          type="email"
          class="form-control"
          placeholder="name@example.com"
          autocomplete="username"
        >
        <label for="email">Email address</label>
        <ValidationMessage :errors="v$.email.$errors" />
      </div>
      <div class="pt-5">
        <LoaderButton
          class="btn btn-primary btn-lg d-block w-100 mb-3"
          :is-loading="isLoading"
          :disabled="isLoading"
        >
          <span class="material-icons-outlined me-2">vpn_key</span> Request Password Reset
        </LoaderButton>
        <div class="text-center">
          <router-link
            :to="{name:'login'}"
            class="text-primary fw-bold d-inline-flex align-items-center text-decoration-none"
          >
            <span class="material-icons-outlined me-2">arrow_back</span> Back to Sign In
          </router-link>
        </div>
      </div>
    </form>
  </AuthCard>
</template>

<style scoped>

</style>

import {
  email,
  helpers,
  required,
  sameAs,
} from '@vuelidate/validators';
import {
  toRef,
} from 'vue';

export default () => ({
  name: {
    required: helpers.withMessage('Name is required', required),
  },
  key: { required: helpers.withMessage('Mailgun key is required', required) },
});

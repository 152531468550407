<script setup>
import {
  defineProps, toRefs,
} from 'vue';

const props = defineProps({
  errors: { type: Array, default: () => [] },
});
const { errors } = toRefs(props);

</script>

<template>
  <div
    v-if="errors.length > 0"
    class="invalid-feedback bg-danger text-white rounded mt-2 p-1 px-2 d-block"
  >
    <div
      v-for="(error, index) of errors"
      :key="index"
      class="feedback-message"
    >
      {{ error.$message }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.feedback-message {
  & + & {
    margin-top: 0.75rem;
  }
}
</style>

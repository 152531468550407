import {
  email, helpers, required, sameAs,
} from '@vuelidate/validators';
import {
  toRef,
} from 'vue';

export default (formData) => ({
  password: {
    required: helpers.withMessage('Password is required', required),
  },
  password_confirmation: {
    sameAs: helpers.withMessage('Passwords do not match', sameAs(toRef(formData, 'password'))),
  },
});

<script setup>
import {
  reactive, ref,

  defineProps,
  toRefs,
} from 'vue';
import { ROUTE } from '@/const';

import { useRouter } from 'vue-router';
import loginRules from '@/validation/rules/login.rules';
import { formDataPreflight } from '@/helpers/form.helpers';

import AuthCard from '@/components/AuthCard.vue';
import LoaderButton from '@/components/LoaderButton.vue';
import ValidationMessage from '@/components/ValidationMessage.vue';

import useVuelidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import UseShake from '@/composables/useShake';
import useAuthStore
  from '@/store/auth.store';
import { storeToRefs } from 'pinia';

const props = defineProps({
  to: { type: String, default: null },
});
const {
  to,
} = toRefs(props);

const authStore = useAuthStore();
const { email } = storeToRefs(authStore);
const { login } = authStore;

const toast$ = useToast();
const router = useRouter();

const isLoading = ref(false);

const formData = reactive({
  email,
  password: '',
});

const v$ = useVuelidate(loginRules(), formData, { $autoDirty: true });

const { shake, isShaking } = UseShake();

const submit = () => {
  v$.value.$validate().then((isValid) => {
    if (isValid) {
      isLoading.value = true;
      const form = formDataPreflight(formData);
      login(form.email, form.password).then(() => {
        router.push(to.value ? JSON.parse(to.value) : { name: ROUTE.APP_HOME });
      }).catch(() => {
        shake();
      }).finally(() => {
        isLoading.value = false;
      });
    } else {
      shake();
      toast$.error('There are problems with the form. Please correct them and try again.');
    }
  });
};

</script>

<template>
  <AuthCard :shake="isShaking">
    <div class="mb-5">
      <h2 class="h3">
        Sign into your account
      </h2>
      <p>Get total visibility of your communications, start by signing in with your Mi Mail account.</p>
    </div>

    <form
      novalidate
      @submit.prevent="submit"
    >
      <div class="form-floating mb-3">
        <input
          id="email"
          v-model="formData.email"
          type="email"
          class="form-control"
          placeholder="name@example.com"
          autocomplete="username"
        >
        <label for="email">Email address</label>
        <ValidationMessage :errors="v$.email.$errors" />
      </div>
      <div class="form-floating mb-3">
        <input
          id="password"
          v-model="formData.password"
          type="password"
          class="form-control"
          placeholder="Password"
          autocomplete="password"
        >
        <label for="password">Password</label>
        <ValidationMessage :errors="v$.password.$errors" />
      </div>
      <div class="text-end">
        <router-link
          :to="{name:ROUTE.FORGOT_PASSWORD}"
          class="text-primary fw-bold"
        >
          Forgotten your password?
        </router-link>
      </div>
      <div class="pt-5">
        <LoaderButton
          class="btn btn-primary btn-lg d-block w-100 mb-3"
          :is-loading="isLoading"
          :disabled="isLoading"
        >
          <span class="material-icons-outlined me-2">login</span> Sign In
        </LoaderButton>
        <div class="text-muted text-center">
          Don't have an account? <router-link
            :to="{name:ROUTE.REGISTER}"
            class="text-primary fw-bold"
          >
            Sign up for free.
          </router-link>
        </div>
      </div>
    </form>
  </AuthCard>
</template>

<style scoped>

</style>

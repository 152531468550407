<script lang="ts" setup>

</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center min-vh-100">
        <router-view v-slot="{ Component }">
          <transition
            mode="out-in"
            name="left-away"
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
  <img
    class="auth-background"
    src="../../assets/background/authBackground.jpg"
  >
</template>

<style lang="scss" scoped>
.auth-background{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}
</style>

<script setup>
import {
  nextTick,
  reactive, ref,
} from 'vue';

import accountRules from '@/validation/rules/account.rules';

import AuthCard from '@/components/AuthCard.vue';
import LoaderButton from '@/components/LoaderButton.vue';
import ValidationMessage from '@/components/ValidationMessage.vue';

import useVuelidate from '@vuelidate/core';
import { useToast } from 'vue-toastification';
import UseShake from '@/composables/useShake';
import useAuthStore
  from '@/store/auth.store';
import useAccountStore
  from '@/store/account.store';
import {
  ROUTE,
} from '@/const';
import {
  useRouter,
} from 'vue-router';
import AccountIdentifier from '@/components/indicators/AccountIdentifier.vue';
import AccountService from '@/api/AccountService.api';
import { formDataPreflight } from '@/helpers/form.helpers';
import { storeToRefs } from 'pinia';

const toast$ = useToast();
const router = useRouter();

const authStore = useAuthStore();
const { logout } = authStore;

const accountStore = useAccountStore();
const { accounts } = storeToRefs(accountStore);

const isLoading = ref(false);

const formData = reactive({
  name: '',
  key: '',
});

const $externalResults = ref({});
const v$ = useVuelidate(accountRules(formData), formData, { $autoDirty: true, $externalResults });

const { shake, isShaking } = UseShake();

const submit = () => {
  v$.value.$validate().then((isValid) => {
    if (isValid) {
      isLoading.value = true;
      AccountService.Call.addOne().fetch(formDataPreflight(formData)).then((data) => {
        if (data.created) {
          toast$.success('Account added! Emails may take a while to synchronise. ');
        } else {
          toast$.success('Account added successfully!');
        }
        accounts.value.push(data.account);
        nextTick(() => {
          router.replace({ name: ROUTE.APP_HOME });
        });
      }).catch((error) => {
        $externalResults.value = error?.response?.data?.errors;
        shake();
      })
        .finally(() => {
          isLoading.value = false;
        });
    } else {
      shake();
      toast$.error('There are problems with the form. Please correct them and try again.');
    }
  });
};

</script>

<template>
  <AuthCard :shake="isShaking">
    <div class="mb-5">
      <h2 class="h3">
        Add Mailgun Account
      </h2>
      <p>To get started syncing your email you'll need to add your first Mailgun account.</p>
    </div>
    <form @submit.prevent="submit">
      <div class="mb-3">
        <div class="d-flex align-items-start">
          <div class="form-floating w-100">
            <input
              id="name"
              v-model="formData.name"
              type="text"
              class="form-control"
              placeholder="Full Name"
              autocomplete="name"
            >
            <label for="name">Account Name</label>
          </div>
          <AccountIdentifier
            :name="formData.name"
            class="flex-shrink-0 ms-3"
            style="width:3.6rem"
          />
        </div>
        <ValidationMessage :errors="v$.name.$errors" />
      </div>

      <div class="form-floating mb-3">
        <input
          id="key"
          v-model="formData.key"
          aria-describedby="keyHelp"
          type="password"
          class="form-control"
          placeholder="Mailgun Key"
        >
        <label for="password">Mailgun Key</label>
        <ValidationMessage :errors="v$.key.$errors" />
        <div
          id="keyHelp"
          class="form-text"
        >
          You can <a
            href="#"
            target="_blank"
          >get your key from your Mailgun Dashboard</a>. Your Mailgun key is encrypted.
        </div>
      </div>
      <div class="pt-5">
        <LoaderButton
          class="btn btn-primary btn-lg d-block w-100 mb-3"
          :is-loading="isLoading"
          :disabled="isLoading"
        >
          <span class="material-icons-outlined me-2">done</span> Let's Get Started
        </LoaderButton>
        <div class="text-center">
          <button
            :to="{name:'login'}"
            type="button"
            class="text-primary fw-bold d-inline-flex align-items-center text-decoration-none btn-link border-0 bg-transparent"
            @click="logout"
          >
            <span class="material-icons-outlined me-2">arrow_back</span> Cancel and sign out
          </button>
        </div>
      </div>
    </form>
  </AuthCard>
</template>

<style scoped>

</style>

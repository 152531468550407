<script setup>
import AuthCard from '@/components/AuthCard.vue';
import {
  reactive,
  ref,
  computed,

  defineProps,
  toRefs,
  watch,
} from 'vue';
import LoaderButton from '@/components/LoaderButton.vue';
import ValidationMessage from '@/components/ValidationMessage.vue';
import useVuelidate
  from '@vuelidate/core';
import passwordResetRules
  from '@/validation/rules/passwordReset.rules';
import UseShake
  from '@/composables/useShake';
import {
  formDataPreflight,
} from '@/helpers/form.helpers';
import {
  ROUTE,
} from '@/const';
import {
  useToast,
} from 'vue-toastification';
import {
  useRouter,
} from 'vue-router';
import useAuthStore
  from '@/store/auth.store';

const props = defineProps({
  email: { type: String, default: '' },
  token: { type: String, default: null },
});

const {
  email, token,
} = toRefs(props);

const toast$ = useToast();
const router = useRouter();

if (token.value === null) {
  toast$.error('No password reset token was provided');
  router.push({ name: ROUTE.LOGIN });
}

const isLoading = ref(false);
const $externalResults = ref({});

const formData = reactive({
  email: '',
  password: '',
  password_confirmation: '',
  token: '',
});

formData.email = email.value;
formData.token = token.value;

const authStore = useAuthStore();
const { resetPassword } = authStore;

const v$ = useVuelidate(passwordResetRules(formData), formData, { $autoDirty: true, $externalResults });

const { shake, isShaking } = UseShake();

const submit = () => {
  v$.value.$validate().then((isValid) => {
    if (isValid) {
      isLoading.value = true;
      const form = formDataPreflight(formData);
      resetPassword(form).then(() => {
        toast$.success('Password reset successfully');
        router.push({ name: ROUTE.HOME });
      }).catch((error) => {
        $externalResults.value = error.response.errors;
        shake();
      }).finally(() => {
        isLoading.value = false;
      });
    } else {
      shake();
      toast$.error('There are problems with the form. Please correct them and try again.');
    }
  });
};

</script>

<template>
  <AuthCard :shake="isShaking">
    <div class="mb-5">
      <h2 class="h3">
        Choose a new password
      </h2>
      <p>Enter a new password ensuring it contains upper and lower case letters, numbers and symbols.</p>
    </div>

    <form @submit.prevent="submit">
      <div
        v-if="formData.email === ''"
        class="form-floating mb-3"
      >
        <input
          id="email"
          v-model="formData.email"
          type="email"
          class="form-control"
          placeholder="name@example.com"
          autocomplete="email"
        >
        <label for="email">Email address</label>
        <ValidationMessage :errors="v$.email.$errors" />
      </div>
      <div class="form-floating mb-3">
        <input
          id="password"
          v-model="formData.password"
          type="password"
          class="form-control"
          placeholder="Password"
          autocomplete="new-password"
        >
        <label for="password">Password</label>
        <ValidationMessage :errors="v$.password.$errors" />
      </div>
      <div class="form-floating mb-3">
        <input
          id="repeatPassword"
          v-model="formData.password_confirmation"
          type="password"
          class="form-control"
          placeholder="Password"
          autocomplete="new-password"
        >
        <label for="repeatPassword">Repeat Password</label>
        <ValidationMessage :errors="v$.password_confirmation.$errors" />
      </div>
      <div class="pt-5">
        <LoaderButton
          type="submit"
          class="btn btn-primary btn-lg d-block w-100 mb-3"
          :is-loading="isLoading"
          :disabled="isLoading"
        >
          <span class="material-icons-outlined me-2">done</span> Reset Password
        </LoaderButton>
        <div class="text-center">
          <router-link
            :to="{name:'login'}"
            class="text-primary fw-bold d-inline-flex align-items-center text-decoration-none"
          >
            <span class="material-icons-outlined me-2">arrow_back</span> Back to Sign In
          </router-link>
        </div>
      </div>
    </form>
  </AuthCard>
</template>

<style scoped>

</style>
